<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcText contentModule hSpace hSpace--colGap" :class="elmClasses" v-if="text">
		<div class="DcText__inner grid grid--cols-8 vSpace vSpace--rowGap">
			<div class="DcText__text" :class="textClasses" v-html="text"></div>
		</div>
		<!--
		<pre name="fontSize">{{fontSize}}</pre>
		<pre name="text">{{text}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DcText',
		components: {},
		mixins: [],
		props: {
			fontSize : [String, Boolean],
			text     : [String, Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			textClasses(){
				let classes = []

				if( this.fontSize == 'normal' ) classes.push('DcText__text--sizeDefault')
				if( this.fontSize == 'big' ) classes.push('DcText__text--sizeBig')

				return classes
			}
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcText {
		&__text {
			grid-column-start: 2;
			grid-column-end: 8;
		}
		&__text--sizeBig {
			.font--sizeBig;
		}
		&__text--sizeDefault {
			.font--sizeDefault;
		}

		@media @mediaQuery__dt {}

		@media @mediaQuery__xs {}
		@media @mediaQuery__sm {
			&__text {
				grid-column: span 8;
			}
			&__text--sizeBig {
				.font--sizeDefault;
			}
			&__text--sizeDefault {
				.font--sizeSmall;
			}
		}
		@media @mediaQuery__md {}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
