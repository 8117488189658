<template>
	<div class="NotFoundView view">

		<Dc404></Dc404>

		<div class="view__spacer"></div>

		<DcFooter></DcFooter>

	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import ViewMixin from './View.mixin.js'
	import Dc404 from '@/components/Dc404.vue'
	import DcFooter from '@/components/DcFooter.vue'

	export default {
		name: 'NotFoundView',
		components: {
			Dc404,
			DcFooter,
		},
		mixins: [
			ViewMixin,
		],
		props: {},
		data() {
			return {}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted() {
			EventBus.$emit('pageLoaded', null)

			this.viewIsReady = true
		}
	}
</script>

<style lang="less" scoped>
	@import "../less/includes/grid.include.less";

	.NotFoundView {}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
