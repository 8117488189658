<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="Dc404 color color--magenta" :class="elmClasses">
		<br />
		<br />
		<DcText fontSize="big" text="Error 404"></DcText>
		<DcText fontSize="normal" text="Diese Seite wurde leider nicht gefunden."></DcText>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import DcText from '@/components/DcText.vue'

	export default {
		name: 'Dc404',
		components: {
			DcText
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.Dc404 {
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
